export interface Column {
  name: string;
  type: 'TEXT' | 'INTEGER' | 'REAL' | 'BLOB';
}

export interface Model {
  name: string;

  columns: Column[];
}

export function createViewStatement(model: Model) {
  const columnNames = model.columns.map((column) => `"${column.name}"`).join(', ');
  const select = model.columns
    .map((column) => `CAST(json_extract(data, '$.${column.name}') as ${column.type})`)
    .join(', ');
  return `CREATE TEMP VIEW IF NOT EXISTS "${model.name}"("id", ${columnNames}) AS SELECT "id", ${select} FROM "objects__${model.name}"`;
}

export function createViewTriggerStatements(model: Model) {
  const type = model.name;
  const jsonFragment = model.columns.map((column) => `'${column.name}', NEW.${column.name}`).join(', ');
  const jsonFragmentOld = model.columns.map((column) => `'${column.name}', OLD.${column.name}`).join(', ');
  return [
    `
CREATE TEMP TRIGGER IF NOT EXISTS view_insert_${type}
INSTEAD OF INSERT ON ${type}
FOR EACH ROW
BEGIN
  INSERT INTO objects__${type}(id, data)
    SELECT NEW.id, json_object(${jsonFragment});
  INSERT INTO crud(data) SELECT json_object('op', 'PUT', 'type', '${type}', 'id', NEW.id, 'data', json(powersync_diff('{}', json_object(${jsonFragment}))));
  INSERT INTO oplog(bucket, op_id, op, object_type, object_id, hash, superseded)
    SELECT '$local',
           1,
           'REMOVE',
           '${type}',
           NEW.id,
           0,
           0;
  INSERT OR REPLACE INTO buckets(name, pending_delete, last_op, target_op) VALUES('$local', 1, 0, 9223372036854775807);
END;`,
    `
CREATE TEMP TRIGGER IF NOT EXISTS view_update_${type}
INSTEAD OF UPDATE ON ${type}
FOR EACH ROW
BEGIN
  SELECT CASE
    WHEN (OLD.id != NEW.id)
    THEN RAISE (FAIL, 'Cannot update id')
  END;
  UPDATE objects__${type}
        SET data = json_object(${jsonFragment})
        WHERE id = NEW.id;
  INSERT INTO crud(data) SELECT json_object('op', 'PATCH', 'type', '${type}', 'id', NEW.id, 'data', json(powersync_diff(json_object(${jsonFragmentOld}), json_object(${jsonFragment}))));
  INSERT INTO oplog(bucket, op_id, op, object_type, object_id, hash, superseded)
    SELECT '$local',
           1,
           'REMOVE',
           '${type}',
           NEW.id,
           0,
           0;
  INSERT OR REPLACE INTO buckets(name, pending_delete, last_op, target_op) VALUES('$local', 1, 0, 9223372036854775807);
END;`,
    `
CREATE TEMP TRIGGER IF NOT EXISTS view_delete_${type}
INSTEAD OF DELETE ON ${type}
FOR EACH ROW
BEGIN
  DELETE FROM objects__${type} WHERE id = OLD.id;
  INSERT INTO crud(data) SELECT json_object('op', 'DELETE', 'type', '${type}', 'id', OLD.id);
END;`
  ];
}

export interface Schema {
  models: Model[];
}
