import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import * as React from 'react';
import { useState } from 'react';
import { Title } from './Title';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { QueryTable } from './QueryTable';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { db } from './db';
import { sql, SqlFragment } from './powersync-client';

const SAMPLE_QUERIES = [
  'UPDATE assets SET quantity = quantity + 1 RETURNING id, quantity',
  `INSERT INTO assets(id, make, model, user_id, customer_id) VALUES (uuid(), 'Test', 'Test', user_id(), (SELECT id FROM customers LIMIT 1)) RETURNING *`,
  'DELETE FROM assets WHERE serial_number IS NULL RETURNING *'
];

const BLANK_DATA = { columns: [], rows: [] };

export function ConsolePage() {
  const [query, setQuery] = useState('');

  const [data, setData] = useState(BLANK_DATA);
  const [error, setError] = useState(null);

  const execute = async () => {
    try {
      await db.internaldb.writeLock(async (ctx) => {
        const before = await ctx.get(`SELECT count(*) as changes from crud`);
        const results = await ctx.all2(query);
        const after = await ctx.get(`SELECT count(*) as changes from crud`);

        setError(null);
        if (results.columns?.length > 0) {
          setData(results);
        } else {
          const changes = after.changes - before.changes;
          setData({
            columns: ['Affected rows'],
            rows: [[changes]]
          });
        }
      });
      db.notify();
    } catch (e) {
      setError(e.message ?? 'Unknown error');
      setData(BLANK_DATA);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <Stack spacing={2}>
            <Title>SQLite Console</Title>

            <Autocomplete
              disablePortal
              fullWidth
              freeSolo
              options={SAMPLE_QUERIES}
              inputValue={query}
              onInputChange={(e, newValue) => setQuery(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Query" error={!!error} helperText={error ?? ' '} />
              )}
            />

            <Button onClick={() => execute()} variant={'contained'}>
              Execute
            </Button>

            <QueryTable data={data} selected={{}} setSelected={null} />
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  );
}
