import type { Schema } from './powersync-client/index.js';

export const SCHEMA: Schema = {
  models: [
    {
      name: 'assets',
      columns: [
        { name: 'created_at', type: 'TEXT' },
        { name: 'make', type: 'TEXT' },
        { name: 'model', type: 'TEXT' },
        { name: 'serial_number', type: 'TEXT' },
        { name: 'quantity', type: 'INTEGER' },
        { name: 'user_id', type: 'TEXT' },
        { name: 'customer_id', type: 'TEXT' }
      ]
    },
    {
      name: 'customers',
      columns: [
        { name: 'name', type: 'TEXT' },
        { name: 'email', type: 'TEXT' }
      ]
    }
  ]
};
