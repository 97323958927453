import { ResultSet, SqlFragment, useQuery } from './powersync-client';
import * as React from 'react';
import { useMemo, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { Title } from './Title';
import LinearProgress from '@mui/material/LinearProgress';
import { memo } from 'react';

export interface QueryTableProps {
  data: ResultSet;
  selected: Record<string, boolean>;
  setSelected: (selected: Record<string, boolean>) => void;
}

export interface InteractiveTableProps {
  title: string;
  queries: string[];
  insert: () => Promise<void>;
  delete: (ids: any[]) => Promise<void>;
}

export function InteractiveTable(props: InteractiveTableProps) {
  const [query, setQuery] = useState(props.queries[0]);
  const queryFragment = useMemo(() => {
    return SqlFragment.raw(query);
  }, [query]);
  const { data, error, loading } = useQuery(queryFragment);
  const hasId = data.columns[0] == 'id';
  const [selected, setSelected] = useState({});

  return (
    <Stack spacing={2}>
      <Title>{props.title}</Title>

      <Autocomplete
        disablePortal
        fullWidth
        freeSolo
        options={props.queries}
        inputValue={query}
        onInputChange={(e, newValue) => setQuery(newValue)}
        renderInput={(params) => <TextField {...params} label="Query" error={!!error} helperText={error ?? ' '} />}
      />

      {loading ? (
        <LinearProgress />
      ) : (
        <React.Fragment>
          <QueryTable data={data} selected={selected} setSelected={hasId ? setSelected : null} />
          <Stack spacing={2} direction="row" justifyContent={'flex-end'}>
            {hasId ? (
              <Button
                variant="contained"
                color={'error'}
                disabled={Object.keys(selected).length == 0}
                onClick={() => {
                  props.delete(Object.keys(selected));
                }}
              >
                Delete Selected
              </Button>
            ) : null}
            <Button variant="contained" color={'primary'} onClick={() => props.insert()}>
              Insert Row
            </Button>
          </Stack>
        </React.Fragment>
      )}
    </Stack>
  );
}

export function QueryTable(props: QueryTableProps) {
  const { data } = props;
  const columns = data.columns;
  const hasCheckbox = props.setSelected != null;
  const { selected, setSelected } = props;
  let selectedRef = React.useRef(selected);
  let setSelectedRef = React.useRef(setSelected);
  let rowsRef = React.useRef(data.rows);
  rowsRef.current = data.rows;
  selectedRef.current = selected;
  setSelectedRef.current = setSelected;

  const select = React.useCallback((id: any, value: boolean) => {
    let copy = {};
    const selected = selectedRef.current;
    const rows = rowsRef.current;
    for (let row of rows) {
      const row_id = row[0];
      if (row_id == id) {
        if (value) {
          copy[row_id] = true;
        }
      } else if (selected[row_id]) {
        copy[row_id] = true;
      }
    }
    setSelectedRef.current(copy);
  }, []);

  let allSelected = true;
  if (hasCheckbox) {
    let selectedCopy = {};
    for (let row of data.rows) {
      if (selected[row[0]]) {
        selectedCopy[row[0]] = true;
      }
    }
    if (Object.keys(selectedCopy).length != Object.keys(selected).length) {
      setTimeout(() => {
        setSelected(selectedCopy);
      }, 0);
    }

    for (let row of data.rows) {
      if (!selected[row[0]]) {
        allSelected = false;
        break;
      }
    }

    if (data.rows.length == 0) {
      allSelected = false;
    }
  }

  const selectAll = (value: boolean) => {
    if (value) {
      let s = {};

      for (let row of data.rows) {
        s[row[0]] = true;
      }
      setSelected(s);
    } else {
      setSelected({});
    }
  };

  return (
    <div style={{ overflowX: 'auto' }}>
      <Table size={'small'}>
        <TableHead>
          <TableRow>
            {hasCheckbox ? (
              <TableCell>
                <Checkbox checked={allSelected} onChange={(event, checked) => selectAll(checked)} />
              </TableCell>
            ) : null}
            {columns.map((column, index) => (
              <TableCell key={index}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.rows.map((row, index) => (
            <DataRow
              key={index}
              index={index}
              rowd={JSON.stringify(row)}
              checked={!!selected[row[0]]}
              checkbox={hasCheckbox}
              select={select}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

const DataRow = memo(function DataRow(props: {
  index: number;
  rowd: string;
  checked: boolean;
  checkbox: boolean;
  select: any;
}) {
  const { index, rowd, checked, checkbox, select } = props;
  const row = JSON.parse(rowd);
  return (
    <TableRow key={index}>
      {checkbox ? (
        <TableCell>
          <Checkbox checked={checked} onChange={(event, checked) => select(row[0], checked)} />
        </TableCell>
      ) : null}
      {row.map((cell, index) => (
        <TableCell key={index}>{cell}</TableCell>
      ))}
    </TableRow>
  );
});
