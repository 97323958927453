import { Schema } from './impl/schemaLogic.js';
import { ProxySqliteDatabase } from './sqlite/ProxySqliteDatabase.js';
import { ResultSet, SqlFragment } from './sql.js';
import { OpfsWorker } from './sqlite/opfs-worker.js';
import * as Comlink from 'comlink/dist/esm/comlink.mjs';
import { SqliteBucketAdapter } from './impl/SqliteBucketAdapter.js';
import { Remote } from './impl/Remote.js';
import { clearToken, getDevToken } from '../auth';
import { StreamingSyncImplementation } from './impl/StreamingSyncImplementation';

export let defaultDb: PowerSyncDatabase;
declare const WORKER_FILES: any;

export class PowerSyncDatabase extends EventTarget {
  public internaldb: ProxySqliteDatabase;
  private workerApi: OpfsWorker;
  private ready: Promise<void>;
  private adapter: SqliteBucketAdapter;
  public completedSync = false;

  constructor(public filename: string, private options: { schema: Schema }) {
    super();
    this.ready = this.init();
    defaultDb = this;
  }

  async query(sql: SqlFragment): Promise<ResultSet> {
    await this.ready;
    const results = await this.internaldb.query(sql);
    if (sql.sql.startsWith('SELECT')) {
      // Read-only
    } else {
      // Perhaps made modifications
      this.internaldb.notify();
    }
    return results;
  }

  notify() {
    this.internaldb.notify();
  }

  private async init() {
    let worker: Worker;
    if (/chrome/i.test(navigator.userAgent) && typeof navigator.storage?.getDirectory != 'undefined') {
      console.debug('OriginPrivateFileSystem');
      worker = new Worker(WORKER_FILES.opfs, { type: 'module' });
    } else {
      console.debug('IDB FileSystem');
      worker = new Worker(WORKER_FILES.idb, {});
    }

    let workerRejectPromise = new Promise((resolve, reject) => {
      worker.addEventListener('error', (event) => {
        reject(new Error(`Failed to initialize database worker: ${event.message}`));
      });
    });

    this.workerApi = Comlink.wrap(worker);
    (window as any).opfs_api = this.workerApi;

    this.internaldb = new ProxySqliteDatabase(this.workerApi, 'test.db');
    this.adapter = new SqliteBucketAdapter(this.internaldb, this.options.schema);
    await Promise.race([this.adapter.ready(), workerRejectPromise]);
  }

  private controller: AbortController;

  connect(remote: Remote) {
    this.controller?.abort();

    if (getDevToken() == null) {
      return;
    }

    const impl = new StreamingSyncImplementation(this.adapter.bucketStorage, remote);
    const controller = new AbortController();
    this.controller = controller;

    const wait = async () => {
      await new Promise<void>((resolve) => setTimeout(resolve, 20));
      await new Promise<void>((resolve) => {
        setTimeout(resolve, 2000);
        const ref = this.internaldb.onChange(() => {
          resolve();
          ref();
        });
      });
    };
    const run = async () => {
      while (!controller.signal.aborted) {
        await this.sync(impl);
        await wait();
      }
    };
    const run2 = async () => {
      while (!controller.signal.aborted) {
        const progress = () => {
          this.lastStreamError = {};
        };
        const { retry } = await impl.streamingSync(controller.signal, progress).catch((err) => {
          if (err.status == 401) {
            clearToken();
            this.dispatchEvent(new Event('signed-out'));
          }
          if (this.lastStreamError?.message == err.message && this.lastStreamError?.timestamp > Date.now() - 10_000) {
            // Ignore
          } else {
            console.error('streaming interrupted', err);
            this.lastStreamError = {
              message: err.message,
              timestamp: Date.now()
            };
          }

          return { retry: false };
        });
        this.dispatchEvent(new Event('statuschange'));
        if (!retry) {
          await wait();
        }
      }
    };

    run();
    run2();
  }

  async delete() {
    await this.workerApi.finalize();
  }

  lastCrudError: any = {};
  lastStreamError: any = {};

  get lastError() {
    if (this.lastCrudError?.message != null) {
      return this.lastCrudError;
    } else {
      return this.lastStreamError;
    }
  }

  isOnline() {
    return this.lastError?.message == null;
  }

  async clear() {
    this.completedSync = false;
    await this.adapter.clear();
    this.controller.abort();
  }

  disableSync = false;

  private async sync(impl: StreamingSyncImplementation) {
    try {
      if (await this.adapter.bucketStorage.hasCompletedSync()) {
        this.completedSync = true;
      }

      if (this.disableSync) {
        throw new Error('Sync Disabled');
      }
      if (getDevToken() == null) {
        this.dispatchEvent(new Event('signed-out'));
        throw new Error('Not authenticated');
      }
      await impl.fullSync();
      this.lastCrudError = {};
    } catch (err) {
      if (err.status == 401) {
        clearToken();
        this.dispatchEvent(new Event('signed-out'));
      }
      if (this.lastCrudError?.message == err.message && this.lastCrudError?.timestamp > Date.now() - 10_000) {
        // Ignore
      } else {
        console.error(err);
        this.lastCrudError = {
          message: err.message,
          timestamp: Date.now()
        };
      }
    }

    if (await this.adapter.bucketStorage.hasCompletedSync()) {
      this.completedSync = true;
    }
    this.dispatchEvent(new Event('statuschange'));
  }
}
