import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { setupConsole } from './console.js';
import { Dashboard } from './Dashboard';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AssetsPage } from './AssetsPage';
import { CustomersPage } from './CustomersPage';
import { ConsolePage } from './Console';

import { Workbox } from 'workbox-window';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import { LoginPage } from './LoginPage';
import { getDevToken } from './auth';
import { db } from './db';

let wb: Workbox;

if ('serviceWorker' in navigator) {
  wb = new Workbox('/service-worker.js');

  wb.addEventListener('controlling', () => {
    window.location.reload();
  });

  wb.register();
}

function UpdateAlert() {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const listener = (event) => {
      setOpen(true);
    };

    wb?.addEventListener('waiting', listener);

    return () => {
      wb?.removeEventListener('waiting', listener);
    };
  });

  return (
    <Snackbar
      open={open}
      action={
        <Button
          color="secondary"
          size="small"
          onClick={() => {
            setOpen(false);
            wb.messageSkipWaiting();
          }}
        >
          Reload
        </Button>
      }
      message="An update is available"
      onClose={() => {
        setOpen(false);
      }}
    />
  );
}

function MyApp() {
  return (
    <React.Fragment>
      <HashRouter>
        <Routes>
          <Route path={'/'} element={<Dashboard />}>
            <Route path={'/'} element={<Navigate to={'/assets'} />}></Route>
            <Route path={'/assets'} element={<AssetsPage />}></Route>
            <Route path={'/customers'} element={<CustomersPage />}></Route>
            <Route path={'/sqlite'} element={<ConsolePage />}></Route>
          </Route>
          <Route path={'/login'} element={<LoginPage />}></Route>
        </Routes>
      </HashRouter>
      <UpdateAlert />
    </React.Fragment>
  );
}

setupConsole();
const root = document.getElementById('container');

ReactDOM.render(<MyApp />, root);

if (getDevToken() == null) {
  location.href = '#/login';
}

db.addEventListener('signed-out', () => {
  location.href = '#/login';
});
