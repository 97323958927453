import { ReactElement, useEffect, useState } from 'react';
import * as React from 'react';

import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import { db } from './db';

export function OnlineIcon() {
  const [forceOffline, setForceOffline] = useState(db.disableSync);

  const [online, setStatus] = useState<any>();
  let error = db.lastError?.message ?? 'Offline';
  if (error == 'Failed to fetch') {
    error = 'Offline';
  }

  useEffect(() => {
    const handleStatusChange = (event) => {
      setStatus(db.isOnline());
    };
    db.addEventListener('statuschange', handleStatusChange);

    return () => {
      db.removeEventListener('statuschange', handleStatusChange);
    };
  });

  function toggleOffline() {
    db.disableSync = !forceOffline;
    setForceOffline(db.disableSync);
  }

  let child: ReactElement;
  if (forceOffline) {
    child = (
      <Tooltip title={'Sync Disabled'}>
        <AirplanemodeActiveIcon />
      </Tooltip>
    );
  } else if (online) {
    child = (
      <Tooltip title={'Online'}>
        <WifiIcon />
      </Tooltip>
    );
  } else {
    child = (
      <Tooltip title={error}>
        <WifiOffIcon />
      </Tooltip>
    );
  }
  return (
    <Badge color="secondary" onClick={toggleOffline}>
      {child}
    </Badge>
  );
}
