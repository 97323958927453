// MIT LICENSE:
// https://github.com/canjs/can-ndjson-stream/blob/master/can-ndjson-stream.js

export function ndjsonStream(response: ReadableStream) {
  // For cancellation
  let is_reader: ReadableStreamDefaultReader,
    cancellationRequest = false;
  return new ReadableStream({
    start: function (controller) {
      const reader = response.getReader();
      is_reader = reader;
      const decoder = new TextDecoder();
      let data_buf = '';

      reader.closed?.then(
        () => {
          cancellationRequest = true;
          controller.close();
        },
        (e) => {
          cancellationRequest = true;
          controller.error(e);
        }
      );

      reader
        .read()
        .then(function processResult(result) {
          if (result.done) {
            if (cancellationRequest) {
              // Immediately exit
              return;
            }

            data_buf = data_buf.trim();
            if (data_buf.length !== 0) {
              try {
                const data_l = JSON.parse(data_buf);
                controller.enqueue(data_l);
              } catch (e) {
                controller.error(e);
                return;
              }
            }
            controller.close();
            return;
          }

          const data = decoder.decode(result.value, { stream: true });
          data_buf += data;
          const lines = data_buf.split('\n');
          for (let i = 0; i < lines.length - 1; ++i) {
            const l = lines[i].trim();
            if (l.length > 0) {
              try {
                const data_line = JSON.parse(l);
                controller.enqueue(data_line);
              } catch (e) {
                controller.error(e);
                cancellationRequest = true;
                reader.cancel(e).catch(() => {});
                return;
              }
            }
          }
          data_buf = lines[lines.length - 1];

          return reader.read().then(processResult);
        })
        .catch((err) => {
          controller.error(err);
          cancellationRequest = true;
          reader.cancel(err).catch(() => {});
        });
    },
    cancel: function (reason) {
      console.log('Cancel registered due to ', reason);
      cancellationRequest = true;
      is_reader.cancel();
    }
  });
}
