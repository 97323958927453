import { Sqlite } from '../sqlite/SqliteEngineV1.js';
import { SqliteBucketStorage } from './SqliteBucketStorage.js';
import { Schema } from './schemaLogic.js';
import { initialize } from './sqlite_migration.js';

export class SqliteBucketAdapter {
  public db: Sqlite.Database;

  private loading = false;
  private readonly promise: Promise<void>;
  public bucketStorage: SqliteBucketStorage;
  public schema: Schema;

  constructor(db: Sqlite.Database, schema: Schema) {
    this.schema = schema;
    this.db = db;
    this.bucketStorage = new SqliteBucketStorage(db);
    this.promise = (async () => {
      try {
        await initialize(db);
        await this.bucketStorage.init();
        await this.updateSchema(schema);
      } finally {
        this.loading = false;
      }
    })();
  }

  async ready() {
    await this.promise;
  }

  description() {
    return 'SQLite';
  }

  getCrudBatch(limit?: number) {
    return this.bucketStorage.getCrudBatch(limit);
  }

  /**
   * Update and rebuild indexes.
   * The entire database is locked while the indexes are being rebuilt.
   */
  async updateSchema(schema: Schema) {
    await this.bucketStorage.updateSchema(schema);
  }

  async getStorageSize() {
    const sizeRow = await this.db.get('PRAGMA page_size');
    const countRow = await this.db.get('PRAGMA page_count');
    const pageSize = sizeRow.page_size;
    const pageCount = countRow.page_count;

    if (pageSize == null || pageCount == null) {
      return null;
    }

    return pageSize * pageCount;
  }

  async clear() {
    const ops: string[] = ['DELETE FROM oplog WHERE 1', 'DELETE FROM buckets WHERE 1', 'DELETE FROM objects WHERE 1'];

    for (let table of this.bucketStorage.tableNames) {
      ops.push(`DELETE FROM ${table} WHERE 1`);
    }
    await this.db.runBatch(ops);
    await this.bucketStorage.init();
    await this.updateSchema(this.schema);
  }
}
