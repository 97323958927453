// This file contains the migration scripts for our SQLite-based database.
// Take care when modifying this.
// The structure is checked against accidental changes in sqliteMigrationSpec.

import type { Sqlite } from '../sqlite/SqliteEngineV1.js';

export const DATABASE_VERSION = 12;

interface MigrationOptions {
  upTo?: number;
}

function* createObjectsTable() {
  yield 'DROP TABLE IF EXISTS objects';
  yield 'CREATE TABLE objects(id TEXT, type TEXT, uid TEXT, hash INTEGER, data TEXT, pending_crud_id INTEGER, attachments TEXT, PRIMARY KEY(type, id))';
}

function* createObjectsTableIndexes(migration = 0) {
  yield 'CREATE INDEX IF NOT EXISTS objects_uid_hash ON objects(uid, hash)'; // Query on uid, but add hash for a covering index
  yield 'CREATE INDEX IF NOT EXISTS objects_by_pending_crud_id ON objects(pending_crud_id) WHERE pending_crud_id IS NOT NULL';
  if (migration != 10) {
    yield 'CREATE INDEX IF NOT EXISTS object_attachments on objects(attachments) WHERE attachments IS NOT NULL';
  }
}

async function cleanSetup(ctx: Sqlite.WriteContext): Promise<number> {
  // Create from scratch.
  // This must give the same result as performing incremental updates up to this version.
  // This may be less than DATABASE_VERSION, in which case incremental updates will be used after this version.
  const version = 11;

  const statements = [
    ...createObjectsTable(),
    ...createObjectsTableIndexes(),
    'CREATE TABLE IF NOT EXISTS crud (id INTEGER PRIMARY KEY AUTOINCREMENT, data TEXT)',

    'DROP TABLE IF EXISTS attachments',
    'CREATE TABLE attachments(id TEXT PRIMARY KEY, media_type TEXT, filename TEXT, local_uri TEXT, queue INTEGER, timestamp INTEGER, size INTEGER, sync INTEGER DEFAULT 0)',
    'CREATE INDEX attachment_queue ON attachments(queue, timestamp)',

    ...downMigrations9(),
    ...downMigrations10(),
    ...downMigrations11(),
    `INSERT OR REPLACE INTO versions(name, version) VALUES('db', ${version})`
  ];

  await ctx.runBatch(statements);

  // Return the version of the database after this setup.
  return version;
}

type MigrationFn = (options: MigrationOptions) => Generator<Sqlite.Query, void>;
type MigrationFnVersioned = (ctx: Sqlite.WriteContext, options: MigrationOptions) => Promise<number>;

const migrations: MigrationFnVersioned[] = [];

/**
 * Simple version - just include migrations statements.
 *
 * @param {number} to - The migration is from version (to - 1) to (to)
 * @param {function} fn - the migration function
 */
function addMigration(to: number, fn: MigrationFn) {
  addDirectMigration(to, async function (ctx, options): Promise<number> {
    function* migrationStatements(): Generator<Sqlite.Query> {
      yield* fn(options);
      yield ['INSERT OR REPLACE INTO versions(name, version) VALUES(?, ?)', 'db', to];
    }

    await ctx.runBatch(migrationStatements());
    return to;
  });
}

/**
 * Advanced version. The function is responsible for running everything. Can include lookups and other logic.
 * In this case, the function itself is responsible for updating the version.
 *
 * @param {number} to - The migration is from version (to - 1) to (to)
 * @param {function} fn - the migration function
 */
function addDirectMigration(to: number, fn: MigrationFnVersioned) {
  const from = to - 1;
  if (migrations[from] != null) {
    throw new Error('Migration from ' + from + 'already exists');
  }

  migrations[from] = fn;
}

// These historical migrations should generally not be changed, to ensure that our migration
// process continues functioning with all previous database versions.
// We can however bypass some of them with more direct migrations for performance reasons (as done in cleanSetup).

addMigration(1, function* () {
  // Not used anymore, but kept for informational purposes and tests.
  yield 'DROP TABLE IF EXISTS objects';
  yield 'CREATE TABLE objects(id TEXT PRIMARY KEY, type TEXT, uid TEXT, hash INTEGER, data TEXT)';
  yield 'CREATE INDEX IF NOT EXISTS objects_type_id ON objects(type, id)';
  yield 'CREATE TABLE IF NOT EXISTS crud (id INTEGER PRIMARY KEY AUTOINCREMENT, data TEXT)';
});

addMigration(2, function* () {
  // Rolled up into migration 9.
  // yield 'CREATE INDEX IF NOT EXISTS objects_uid ON objects(uid)';
});

addMigration(3, function* () {
  // No change
});

addMigration(4, function* () {
  // Rolled up into migration 9.
  // yield 'DROP TABLE IF EXISTS object_indexes';
  // yield 'ALTER TABLE objects ADD COLUMN index_version INTEGER';
  // yield 'CREATE INDEX IF NOT EXISTS objects_by_index_version ON objects(type, index_version)';
});

addMigration(5, function* () {});

addMigration(6, function* () {
  yield 'ALTER TABLE objects ADD COLUMN pending_crud_id INTEGER';
  // Rolled up into migration 9.
  // yield 'CREATE INDEX IF NOT EXISTS objects_by_pending_crud_id ON objects(pending_crud_id) WHERE pending_crud_id IS NOT NULL';
});

addMigration(7, function* () {
  yield 'DROP TABLE IF EXISTS attachments';
  yield 'CREATE TABLE attachments(id TEXT PRIMARY KEY, media_type TEXT, filename TEXT, local_uri TEXT, queue INTEGER, timestamp INTEGER, size INTEGER)';
  yield 'CREATE INDEX attachment_queue ON attachments(queue, timestamp)';
});

addMigration(8, function* () {
  // Rolled up into migration 9.
  // // This is just to change the primary key.
  // yield 'ALTER TABLE objects RENAME TO objects_migrate';
  // yield* createObjectsTable();
  // // Update this column list if new columns are added
  // yield 'INSERT INTO objects SELECT id, type, uid, hash, data, index_version, pending_crud_id FROM objects_migrate';
  // yield 'DROP TABLE objects_migrate';
  // // The indexes must be created after the _migrate table is dropped, otherwise the index names will conflict.
  // yield* createObjectsTableIndexes();
});

function* downMigrations9(): Generator<Sqlite.Query> {
  function* deleteTablesOps() {
    yield 'DROP TABLE IF EXISTS objects';
    yield 'DROP TABLE IF EXISTS object_indexes';
    yield 'DROP TABLE IF EXISTS crud';
    yield 'DROP TABLE IF EXISTS attachments';
    yield 'DROP TABLE IF EXISTS keystore';
    // These two exist outside the migrations - delete the data, but keep the tables
    yield 'DELETE FROM versions';
    yield 'DELETE FROM down_migrations';
  }

  // In practice, we should never migrate back from 9 -> 7, or even 8. No code at version 7 or 8 will handle this migration.
  // But we include this here as a sample and a test case.
  const clearOps = JSON.stringify([...deleteTablesOps()]);
  yield [
    'INSERT OR REPLACE INTO down_migrations(version, queries) VALUES(?, ?)',
    8,
    JSON.stringify(['DELETE FROM down_migrations', "INSERT OR REPLACE INTO versions(name, version) VALUES('db', 8)"])
  ];
  yield ['INSERT OR REPLACE INTO down_migrations(version, queries) VALUES(?, ?)', 7, clearOps];
}

addMigration(9, function* () {
  // Pseudo-migration
  // Does not really migrate anything significant, but it's the first version that supports down migrations, and
  // we mark it with a new version.
  yield* downMigrations9();

  yield ['DELETE FROM down_migrations WHERE version > ?', 8];
});

function* downMigrations10(): Generator<Sqlite.Query> {
  const downMigrationOps = [
    // We don't populate the data on this. If JSON1 is used, it's not relevant. If JSON1 is not used, the index building
    // logic will have to take care of that.
    // This is a more complicated version of the following, just to get it in the right position.:
    //   ALTER TABLE objects ADD COLUMN index_version INTEGER
    'ALTER TABLE objects RENAME TO objects_downmigrate',
    'CREATE TABLE objects(id TEXT, type TEXT, uid TEXT, hash INTEGER, data TEXT, index_version INTEGER, pending_crud_id INTEGER, PRIMARY KEY(type, id))',
    'INSERT INTO objects(id, type, uid, hash, data, pending_crud_id) SELECT id, type, uid, hash, data, pending_crud_id FROM objects_downmigrate',
    'DROP TABLE objects_downmigrate',
    'CREATE INDEX objects_by_index_version ON objects(type, index_version)',
    'CREATE INDEX objects_by_pending_crud_id ON objects(pending_crud_id) WHERE pending_crud_id IS NOT NULL',
    'CREATE INDEX objects_uid ON objects(uid)',
    'CREATE TABLE object_indexes (id TEXT, type TEXT, index_name TEXT, key BLOB, index_version)',
    'CREATE INDEX indexes_by_key ON object_indexes(type, index_name, key)',
    'CREATE INDEX indexes_by_object ON object_indexes(type, id)',
    'CREATE INDEX indexes_by_type_version ON object_indexes(type, index_version)',
    'DELETE FROM down_migrations WHERE version >= 9',
    "INSERT OR REPLACE INTO versions(name, version) VALUES('db', 9)"
  ];
  yield ['INSERT OR REPLACE INTO down_migrations(version, queries) VALUES(?, ?)', 9, JSON.stringify(downMigrationOps)];
}

addMigration(10, function* (options) {
  yield 'DROP TABLE IF EXISTS object_indexes';
  // yield 'DROP INDEX IF EXISTS objects_by_index_version';

  // This is just to drop the index_version column.
  yield 'ALTER TABLE objects RENAME TO objects_migrate';
  yield 'CREATE TABLE objects(id TEXT, type TEXT, uid TEXT, hash INTEGER, data TEXT, pending_crud_id INTEGER, PRIMARY KEY(type, id))';
  // Update this column list if new columns are added

  // Remove all fields from data except for 'attributes' and 'belongs_to'.
  const jsonExtract = `json_object('attributes', json_extract(data, '$.attributes'), 'belongs_to', json_extract(data, '$.belongs_to'))`;
  yield `INSERT INTO objects SELECT id, type, uid, hash, ${jsonExtract} as data, pending_crud_id FROM objects_migrate`;

  yield 'DROP TABLE objects_migrate';
  // The indexes must be created after the _migrate table is dropped, otherwise the index names will conflict.
  yield* createObjectsTableIndexes(10);

  yield* downMigrations10();
});

function* downMigrations11(): Generator<Sqlite.Query> {
  // 11 -> 10
  const downMigrationOps = [
    // objects table -> remove attachments column
    'ALTER TABLE objects RENAME TO objects_downmigrate',
    'CREATE TABLE objects(id TEXT, type TEXT, uid TEXT, hash INTEGER, data TEXT, pending_crud_id INTEGER, PRIMARY KEY(type, id))',
    'INSERT INTO objects(id, type, uid, hash, data, pending_crud_id) SELECT id, type, uid, hash, data, pending_crud_id FROM objects_downmigrate',
    'DROP TABLE objects_downmigrate',
    ...createObjectsTableIndexes(10),

    // attachments table -> remove sync column
    'ALTER TABLE attachments RENAME TO attachments_downmigrate',
    'CREATE TABLE attachments(id TEXT PRIMARY KEY, media_type TEXT, filename TEXT, local_uri TEXT, queue INTEGER, timestamp INTEGER, size INTEGER)',
    'INSERT INTO attachments(id, media_type, filename, local_uri, queue, timestamp, size) SELECT id, media_type, filename, local_uri, queue, timestamp, size FROM attachments_downmigrate',
    'DROP TABLE attachments_downmigrate',
    'CREATE INDEX attachment_queue ON attachments(queue, timestamp)',

    'DELETE FROM down_migrations WHERE version >= 10',
    "INSERT OR REPLACE INTO versions(name, version) VALUES('db', 10)"
  ];
  yield ['INSERT OR REPLACE INTO down_migrations(version, queries) VALUES(?, ?)', 10, JSON.stringify(downMigrationOps)];
}

addDirectMigration(11, async function (ctx, options) {
  // This migration needs to recover from the case where the current version is stored as 8, but these columns and indexes
  // all already exist.
  const { count_attachments_sync } = await ctx.get(
    "SELECT COUNT(*) AS count_attachments_sync FROM pragma_table_info('attachments') WHERE name='sync'"
  );
  const { count_objects_attachments } = await ctx.get(
    "SELECT COUNT(*) AS count_objects_attachments FROM pragma_table_info('objects') WHERE name='attachments'"
  );

  let statements: Sqlite.Query[] = [];
  if (count_attachments_sync != 1) {
    statements.push('ALTER TABLE attachments ADD COLUMN sync INTEGER DEFAULT 0');
  }
  if (count_objects_attachments != 1) {
    statements.push('ALTER TABLE objects ADD COLUMN attachments TEXT');
  }
  statements.push(...createObjectsTableIndexes());
  statements.push(...downMigrations11());
  statements.push(['INSERT OR REPLACE INTO versions(name, version) VALUES(?, ?)', 'db', 11]);

  await ctx.runBatch(statements);
  return 11;
});

function downMigrations12(): Sqlite.Query {
  // 12 -> 11
  const downMigrationOps = [
    // objects table -> remove attachments column
    'DROP TABLE oplog',
    'DROP TABLE buckets',
    'DROP TABLE objects_updates',
    'DROP TABLE objects_untyped',
    'DELETE FROM down_migrations WHERE version >= 11',
    "INSERT OR REPLACE INTO versions(name, version) VALUES('db', 11)"
  ];
  // This down migration does not delete objects__${type} tables.
  return [
    'INSERT OR REPLACE INTO down_migrations(version, queries) VALUES(?, ?)',
    11,
    JSON.stringify(downMigrationOps)
  ];
}

addDirectMigration(12, async function (ctx, options) {
  let ops: Sqlite.Query[] = [];

  ops.push(
    `CREATE TABLE IF NOT EXISTS oplog(` +
      `bucket TEXT NOT NULL, ` +
      // 64-bit integer
      `op_id INTEGER NOT NULL, ` +
      // 'PUT' or 'REMOVE
      `op TEXT NOT NULL, ` +
      `object_type TEXT, ` +
      `object_id TEXT, ` +
      // JSON blob
      `data TEXT, ` +
      `hash INTEGER NOT NULL, ` +
      // boolean (1 or 0)
      `superseded INTEGER NOT NULL)`
  );

  // We use this to group by object, and to mark previous entries as superseded
  ops.push(`CREATE INDEX IF NOT EXISTS oplog_by_object ON oplog (object_type, object_id) WHERE superseded = 0`);
  ops.push(`CREATE INDEX IF NOT EXISTS oplog_by_opd ON oplog (bucket, op_id)`);

  ops.push(
    `CREATE TABLE IF NOT EXISTS buckets(` +
      `name TEXT PRIMARY KEY, ` +
      // Last op_id that we have synced over to separate tables.
      'last_applied_op INTEGER NOT NULL DEFAULT 0, ' +
      // Last op_id in the oplog table for this bucket
      'last_op INTEGER NOT NULL DEFAULT 0, ' +
      // If last_op < target_op, the data is not considered consistent, and we shouldn't sync over to the local db.
      'target_op INTEGER NOT NULL DEFAULT 0, ' +
      // Add this number to the checksum for this bucket. Used to compact data.
      'add_checksum INTEGER NOT NULL DEFAULT 0, ' +
      // 1 if this is a temporary bucket that should be deleted after syncing over.
      'pending_delete INTEGER NOT NULL DEFAULT 0' +
      `)`
  );

  ops.push(
    `CREATE TABLE IF NOT EXISTS objects_updates(op_id INTEGER PRIMARY KEY NOT NULL, type TEXT NOT NULL, id TEXT NOT NULL, data TEXT, buckets TEXT NOT NULL)`
  );

  ops.push(
    `CREATE TABLE IF NOT EXISTS objects_untyped(type TEXT NOT NULL, id TEXT NOT NULL, data TEXT, PRIMARY KEY (type, id))`
  );

  await ctx.runBatch([
    downMigrations12(),
    ...ops,
    ['INSERT OR REPLACE INTO versions(name, version) VALUES(?, ?)', 'db', 12]
  ]);

  return 12;
});

/**
 * Initialize a database, performing any required migrations.
 *
 * @param db
 * @param options.upTo - FOR TESTS ONLY! Use this to migrate to a specific version, instead of the latest version.
 */
export async function initialize(db: Sqlite.Database, options?: MigrationOptions) {
  const upTo = options?.upTo ?? DATABASE_VERSION;
  if (typeof upTo != 'number') {
    throw new Error('upTo must be a number');
  }

  await db.writeLock(async (ctx) => {
    let version: number;
    const setup: Sqlite.Query[] = [
      'CREATE TABLE IF NOT EXISTS versions(name TEXT PRIMARY KEY, version INTEGER)',
      'CREATE TABLE IF NOT EXISTS down_migrations(version INTEGER PRIMARY KEY, queries TEXT)'
    ];
    await ctx.runBatch(setup);
    const row = await ctx.get('SELECT * FROM versions WHERE name=?', 'db');
    if (row) {
      version = row.version;
    } else {
      version = 0;
    }

    if (version == upTo) {
      return;
    } else if (version > upTo) {
      // Migrate down
      const downMigrationRows = await ctx.all(
        'SELECT * from down_migrations WHERE version >= ? and version < ? ORDER BY version DESC',
        upTo,
        version
      );

      for (let row of downMigrationRows) {
        const migrations = JSON.parse(row.queries);
        await ctx.runBatch(migrations);
      }

      // The down migrations should have updated the version. May need to do an up migration again.
      const row2 = await ctx.get('SELECT * FROM versions WHERE name=?', 'db');
      if (row2) {
        version = row2.version;
      } else {
        version = 0;
      }
    }

    // Migrate up
    await migrate(ctx, version, options, upTo);
  });
}

async function migrate(
  ctx: Sqlite.WriteContext,
  version: number,
  options: MigrationOptions | undefined,
  upTo: number
): Promise<void> {
  // In all cases except tests, upTo == DATABASE_VERSION.
  if (version == 0 && upTo == DATABASE_VERSION) {
    // More direct setup script for new databases.
    // Incremental migrations may still run after this.
    version = await cleanSetup(ctx);
  }

  while (version < upTo) {
    let migration = migrations[version];
    if (migration == null) {
      // No change in this version
      throw new Error('Failed to migrate: no migration from version ' + version);
    } else if (typeof migration != 'function') {
      throw new Error('Migration is not a function ' + migration);
    } else {
      version = await migration(ctx, options);
    }
  }
}

export async function clearDatabase(db: Sqlite.Database, keepCrud?: boolean) {
  await db.writeLock(async (tx) => {
    const tableRows = await tx.all(`SELECT name FROM sqlite_master WHERE type = 'table'`);
    const tables = new Set<string>(tableRows.map((row) => row.name));
    async function clearTable(table: string) {
      if (tables.has(table)) {
        await tx.run(`DELETE FROM ${table}`);
      }
    }

    await clearTable('objects');
    await clearTable('object_indexes');
    await clearTable('keystore');
    await clearTable('objects_updates');
    await clearTable('objects_untyped');
    await clearTable('oplog');
    await clearTable('buckets');

    const objects_tables = [...tables].filter((name) => name.startsWith('objects__'));
    for (let table of objects_tables) {
      await tx.run(`DROP TABLE "${table}"`);
    }

    if (!keepCrud) {
      await clearTable('crud');
      await clearTable('attachments');
    }
  });
}
