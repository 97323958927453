import { db } from './db';
import { sql } from './powersync-client';
import * as React from 'react';
import { InteractiveTable } from './QueryTable';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

async function loadFaker() {
  return import('@faker-js/faker/locale/en_US');
}

export function CustomersPage() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <CustomersTable />
        </Paper>
      </Grid>
    </Grid>
  );
}

export function CustomersTable() {
  const insert = async () => {
    const { faker } = await loadFaker();

    const name = faker.name.firstName();
    const email = faker.internet.exampleEmail(name);
    await db.query(sql`INSERT INTO customers(id, name, email) VALUES (${faker.datatype.uuid()}, ${name}, ${email})`);
  };

  const del = async (ids: any[]) => {
    await db.query(
      sql`DELETE FROM customers WHERE id IN (SELECT json_each.value FROM json_each(${JSON.stringify(ids)}))`
    );
  };

  return <InteractiveTable title={'Customers'} queries={['SELECT * FROM customers']} insert={insert} delete={del} />;
}
