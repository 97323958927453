export class SqlFragment {
  static raw(sql: string) {
    return new SqlFragment(sql);
  }

  args: any[];

  constructor(public sql: string, args?: any[]) {
    this.args = args ?? [];
  }
}

export function sql(a: TemplateStringsArray, ...args: any[]) {
  return new SqlFragment(a.join('?'), args);
}

export interface ResultSet {
  columns: string[];
  rows: any[][];
}
