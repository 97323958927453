import type { Sqlite } from './SqliteEngineV1';
import { Mutex, MutexContext, SharedMutexContext } from './Mutex.js';
import { matchingArray, sanitizeArgs, sanitizeQuery } from './sqliteUtil.js';
import type { OpfsWorker } from './opfs-worker.js';
import type { ResultSet } from '../sql.js';
import debounce from 'lodash-es/debounce.js';
import { time } from '../impl/performance.js';
import { SqlFragment } from '../sql.js';
import { getUserId } from '../../auth';

const SUPER_VERBOSE = false;

class ProxySqliteTransaction implements Sqlite.ReadContext {
  protected context: SharedMutexContext | MutexContext;
  protected db: ProxySqliteDatabase;
  protected name: string;

  constructor(context: SharedMutexContext, db: ProxySqliteDatabase, name: string) {
    this.context = context;
    this.db = db;
    this.name = name;
  }

  async get(sql: string, ...args: any[]): Promise<any> {
    return this.db.driver.get(sql, args);
  }

  async getBatch(queries: Iterable<Sqlite.Query>): Promise<any[]> {
    let results: any[] = matchingArray(queries);
    let i = 0;
    let promises = [];
    for (let query of queries) {
      const index = i;
      i += 1;

      let { sql, args } = sanitizeQuery(query);

      promises.push(
        this.get(sql, ...args).then((result) => {
          results[index] = result;
        })
      );
    }
    await Promise.all(promises);
    return results;
  }

  async all(sql: string, ...args: any[]): Promise<any[]> {
    return time(this.db.driver.all(sql, args), `main all ${sql}`);
  }

  async all2(sql: string, ...args: any[]): Promise<ResultSet> {
    return time(this.db.driver.all2(sql, args), `main all2 ${sql}`);
  }

  readLock<T>(fn: (tx: Sqlite.ReadContext) => Promise<T>): Promise<T> {
    return fn(this);
  }
}

class WritableProxySqliteTransaction extends ProxySqliteTransaction implements Sqlite.WriteContext {
  private exclusiveContext: MutexContext;

  constructor(context: MutexContext, driver: ProxySqliteDatabase, name: string) {
    super(context, driver, name);
    this.exclusiveContext = context;
  }

  private async _run(sql: string, args?: any[]): Promise<Sqlite.Result> {
    try {
      args = sanitizeArgs(args);

      await this.get(sql, ...(args ?? []));
      return { changes: 0, lastID: 0 }; // FIXME
    } catch (err) {
      throw SqliteError.forSql(err, sql);
    }
  }

  async runBatch(queries: Iterable<Sqlite.Query>): Promise<Sqlite.Result[]> {
    return this.exclusiveContext.exclusiveLock(async () => {
      const q = [...queries];
      const res = await time(this.db.driver.runBatch(q), `main runBatch ${JSON.stringify(q[0])}`);
      this.db.notify();
      return res;
    });
  }

  async run(sql: string, ...args: any[]): Promise<Sqlite.Result> {
    return this.exclusiveContext.exclusiveLock(async () => {
      const res = await time(this.db.driver.run(sql, args), `main run ${sql}`);
      this.db.notify();
      return res;
    });
  }

  async writeLock<T>(fn: (tx: Sqlite.WriteContext) => Promise<T>): Promise<T> {
    return fn(this);
  }
}

export class ProxySqliteDatabase implements Sqlite.Database {
  mutex = new Mutex();
  private name: string;
  private filename: string;
  private ready: Promise<void>;

  constructor(public driver: OpfsWorker, filename: string) {
    this.name = /(.+\/)?(.+?)$/.exec(filename)[2];
    this.filename = filename;
    this.ready = this.init();
  }

  private async init() {
    await this.driver.initialize({});
    await this.driver.setUserId(getUserId());
  }

  async close(): Promise<void> {}

  /**
   * Register listeners for Sqlite3 events.
   */
  on(eventName: 'trace' | 'profile' | 'error' | 'open' | 'close', listener: (...args: any[]) => void) {
    // this.driver.on(eventName, listener);
  }

  readLock<T>(fn: (tx: ProxySqliteTransaction) => Promise<T>): Promise<T> {
    return this.mutex.exclusiveLock(async (context) => {
      await this.ready;
      return fn(new ProxySqliteTransaction(context, this, this.name));
    });
  }

  writeLock<T>(fn: (tx: Sqlite.WriteContext) => Promise<T>): Promise<T> {
    return this.mutex.exclusiveLock(async (context) => {
      await this.ready;
      return fn(new WritableProxySqliteTransaction(context, this, this.name));
    });
  }

  run(sql: string, ...args: any[]): Promise<Sqlite.Result> {
    return this.writeLock((tx) => tx.run(sql, ...args));
  }

  query(sql: SqlFragment): Promise<ResultSet> {
    return this.all2(sql.sql, sql.args);
  }

  runBatch(queries: Iterable<Sqlite.Query>): Promise<Sqlite.Result[]> {
    return this.writeLock((tx) => tx.runBatch(queries));
  }

  get(sql: string, ...args: any[]): Promise<any> {
    return this.readLock((tx) => tx.get(sql, ...args));
  }

  getBatch(queries: Iterable<Sqlite.Query>): Promise<any[]> {
    return this.readLock((tx) => tx.getBatch(queries));
  }

  all(sql: string, ...args: any[]): Promise<any[]> {
    return this.readLock((tx) => tx.all(sql, ...args));
  }

  all2(sql: string, ...args: any[]): Promise<ResultSet> {
    return this.readLock((tx) => tx.all2(sql, ...args));
  }

  async getTableNames(): Promise<string[]> {
    const rows = await this.all('SELECT name FROM sqlite_master WHERE type="table"');
    return rows.map((row) => row.name);
  }

  private callbacks = new Set<{ cb: () => void }>();
  private lastChanges = -1;

  public notify = debounce(async () => {
    const { changes } = await this.get('SELECT total_changes() as changes');
    if (changes > this.lastChanges) {
      for (let callback of this.callbacks) {
        callback.cb();
      }
      this.lastChanges = changes;
    }
  }, 10);

  onChange(cb: any) {
    const ref = { cb };
    this.callbacks.add(ref);
    return () => {
      this.callbacks.delete(ref);
    };
  }
}

class SqliteError extends Error {
  public cause?: any;
  public sql?: string;

  constructor(options: { cause?: any; sql?: string; message: string }) {
    super(options.message);

    this.cause = options.cause;
    this.sql = options.sql;
  }

  static forSql(cause: any, sql: string) {
    return new SqliteError({ cause, sql, message: errorMessage(cause, sql) });
  }
}

function errorMessage(cause: any, sql: string) {
  let causeMessage = cause && cause.message ? cause.message : 'SQL Error';
  if (sql) {
    return `${causeMessage} on ${sql}`;
  } else {
    return causeMessage;
  }
}
