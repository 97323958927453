import Paper from '@mui/material/Paper';
import * as React from 'react';
import { useState } from 'react';
import { Title } from './Title';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { setPgwebPassword, setToken } from './auth';
import LoadingButton from '@mui/lab/LoadingButton';
import { isSupportedBrowser } from './browser-check';
import Typography from '@mui/material/Typography';

export function LoginPage() {
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState('10000000-0000-0000-a1a1-000000000001');
  const supported = isSupportedBrowser();

  const login = async () => {
    setLoading(true);
    setError(null);
    try {
      const res = await fetch('/dev/auth.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user: user,
          password: password
        })
      });
      if (res.ok) {
        const { data } = await res.json();
        setToken(data.user_id, data.token);

        setError(null);
      } else {
        throw new Error('Invalid password');
      }
    } catch (e) {
      setError(e.message);
      return;
    } finally {
      setLoading(false);
    }
    location.href = '#/';
  };

  if (!supported) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h3">Browser not supported</Typography>
          <Typography variant="body1">
            Supported browsers:
            <br />
            <ul>
              <li>Chrome (lastest stable version)</li>
              <li>Firefox (lastest stable version)</li>
              <li>Safari (version 16+)</li>
            </ul>
          </Typography>
        </Paper>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <form>
          <Stack spacing={2}>
            <Title>PowerSync Demo</Title>

            <FormControl fullWidth>
              <InputLabel id="select-label">User</InputLabel>
              <Select value={user} label="User" onChange={(e) => setUser(e.target.value)} labelId="select-label">
                <MenuItem value={'10000000-0000-0000-a1a1-000000000001'}>User 1</MenuItem>
                <MenuItem value={'20000000-0000-0000-a1a1-000000000002'}>User 2</MenuItem>
                <MenuItem value={'30000000-0000-0000-a1a1-000000000003'}>User 3</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <TextField
                fullWidth
                type={'password'}
                value={password}
                label={'Demo Password'}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                error={!!error}
                helperText={error ?? ' '}
              />
            </FormControl>

            <LoadingButton type={'submit'} onClick={() => login()} variant={'contained'} loading={loading}>
              Login
            </LoadingButton>
          </Stack>
        </form>
      </Paper>
    </Container>
  );
}
