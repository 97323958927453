import { defaultDb, Remote } from './powersync-client';
import { db } from './db';

export function setToken(user_id: string, token: string) {
  localStorage.setItem('user_id', user_id);
  localStorage.setItem('token', token);
  defaultDb.notify();

  db.internaldb.driver.setUserId(user_id);
  db.connect(new Remote());
}

export function setPgwebPassword(pwd: string) {
  localStorage.setItem('pgweb_password', pwd);
}

export function getPgwebPassword() {
  return localStorage.getItem('pgweb_password');
}

export function getDevToken() {
  return localStorage.getItem('token');
}

export function getUserId() {
  return localStorage.getItem('user_id');
}

export function clearToken() {
  localStorage.removeItem('token');
}
