export function isSupportedBrowser() {
  const isChrome = navigator.userAgent.indexOf('Chrome') >= 0;
  const isSafari = !isChrome && navigator.userAgent.indexOf('Safari') >= 0;
  const isFirefox = navigator.userAgent.indexOf('Firefox') >= 0;

  if (isChrome) {
    return typeof navigator.storage?.getDirectory != 'undefined';
  } else if (isSafari) {
    const match = / Version\/(\d+)/.exec(navigator.userAgent);
    if (!match) {
      return false;
    }
    const version = parseInt(match[1]);
    return version >= 16;
  } else if (isFirefox) {
    const match = / Firefox\/(\d+)/.exec(navigator.userAgent);
    if (!match) {
      return false;
    }
    const version = parseInt(match[1]);
    return version >= 104;
  } else {
    return false;
  }
}
