import { getDevToken, getUserId } from '../../auth';

export class Remote {
  constructor() {}

  async getToken() {
    const devToken = getDevToken();
    if (devToken == null) {
      const error: any = new Error(`Not signed in`);
      error.status = 401;
      throw error;
    }

    const body = await this.post('dev/token.json', {}, devToken);
    const { token } = body.data;
    return token;
  }

  async post(path: string, data: any, token?: string) {
    const url = path;
    const res = await fetch(url, {
      headers: {
        'content-type': 'application/json',
        'User-Id': getUserId(),
        Authorization: `Token ${token ?? (await this.getToken())}`
      },
      method: 'POST',
      body: JSON.stringify(data)
    });
    if (!res.ok) {
      const error: any = new Error(`HTTP ${res.statusText}: ${await res.text()}`);
      error.status = res.status;
      throw error;
    }
    return await res.json();
  }

  async postStreaming(path: string, data: any, signal?: AbortSignal) {
    const res = await fetch(path, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'User-Id': getUserId(),
        Authorization: `Token ${await this.getToken()}`
      },
      body: JSON.stringify(data),
      signal,
      cache: 'no-store'
    });
    if (!res.ok) {
      const error: any = new Error(`HTTP ${res.statusText}: ${await res.text()}`);
      error.status = res.status;
      throw error;
    }
    return res.body;
  }

  isAvailable() {
    return true;
  }
}
