function isProfilingEnabled() {
  return false;
}

export async function time<T>(
  promise: Promise<T> | (() => Promise<T>),
  description: string | (() => string)
): Promise<T> {
  if (typeof promise == 'function') {
    promise = promise();
  }
  if (!isProfilingEnabled()) {
    return promise;
  }

  const start = performance.now();
  try {
    return await promise;
  } finally {
    const end = performance.now();
    const d = typeof description == 'string' ? description : description();
    // This `performance.measure` fails on old WebViews thus we need to try/catch
    try {
      performance.measure(d, { start, end });
    } catch (e) {
      // Ignore
    }
    const duration = end - start;
    console.debug('%c%s %c%s', 'color: grey', d, durationStyle(duration), `(${duration.toFixed(1)}ms)`);
  }
}

function durationStyle(duration: number) {
  if (duration < 30) {
    return 'color: grey';
  } else if (duration < 300) {
    return 'color: blue';
  } else {
    return 'color: red';
  }
}
