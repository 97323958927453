/**
 * For sync2.json
 */
export interface ContinueCheckpointRequest {
  /**
   * Existing bucket states. Only these buckets are synchronized.
   */
  buckets: BucketRequest[];

  checkpoint_token: string;

  limit?: number;
}

export interface SyncNewCheckpointRequest {
  /**
   * Existing bucket states. Used if include_data is specified.
   */
  buckets?: BucketRequest[];

  request_checkpoint: {
    /**
     * Whether or not to include an initial data request.
     */
    include_data: boolean;

    /**
     * Whether or not to compute a checksum.
     */
    include_checksum: boolean;
  };

  limit?: number;
}

export type SyncRequest = ContinueCheckpointRequest | SyncNewCheckpointRequest;

export interface SyncResponse {
  /**
   * Data for the buckets returned. May not have an an entry for each bucket in the request.
   */
  data?: SyncBucketData[];

  /**
   * True if the response limit has been reached, and another request must be made.
   */
  has_more: boolean;

  checkpoint_token?: string;

  checkpoint?: Checkpoint;
}

export interface StreamingSyncRequest {
  /**
   * Existing bucket states.
   */
  buckets?: BucketRequest[];

  /**
   * If specified, limit the response to only include these buckets.
   */
  only?: string[];

  /**
   * Whether or not to compute a checksum for each checkpoint
   */
  include_checksum: boolean;
}

export interface StreamingSyncCheckpoint {
  checkpoint: Checkpoint;
}

export interface StreamingSyncCheckpointDiff {
  checkpoint_diff: {
    last_op_id: OpId;
    updated_buckets: BucketChecksum[];
    removed_buckets: string[];
  };
}

export interface StreamingSyncData {
  data: SyncBucketData;
}

export interface StreamingSyncCheckpointComplete {
  checkpoint_complete: {
    last_op_id: OpId;
  };
}

export interface StreamingSyncKeepalive {
  /** If specified, token expires in this many seconds. */
  token_expires_in: number;
}

export type StreamingSyncLine =
  | StreamingSyncData
  | StreamingSyncCheckpoint
  | StreamingSyncCheckpointDiff
  | StreamingSyncCheckpointComplete
  | StreamingSyncKeepalive;

export function isStreamingSyncData(line: StreamingSyncLine): line is StreamingSyncData {
  return (line as StreamingSyncData).data != null;
}

export function isStreamingKeepalive(line: StreamingSyncLine): line is StreamingSyncKeepalive {
  return (line as StreamingSyncKeepalive).token_expires_in != null;
}

export function isStreamingSyncCheckpoint(line: StreamingSyncLine): line is StreamingSyncCheckpoint {
  return (line as StreamingSyncCheckpoint).checkpoint != null;
}

export function isStreamingSyncCheckpointComplete(line: StreamingSyncLine): line is StreamingSyncCheckpointComplete {
  return (line as StreamingSyncCheckpointComplete).checkpoint_complete != null;
}

export function isStreamingSyncCheckpointDiff(line: StreamingSyncLine): line is StreamingSyncCheckpointDiff {
  return (line as StreamingSyncCheckpointDiff).checkpoint_diff != null;
}

export interface BucketRequest {
  name: string;

  /**
   * Base-10 number. Sync all data from this bucket with op_id > after.
   */
  after: OpId;
}

/**
 * 64-bit unsigned number, as a base-10 string.
 */
export type OpId = string;

export interface Checkpoint {
  last_op_id: OpId;
  buckets: BucketChecksum[];
}

export interface BucketState {
  bucket: string;
  op_id: string;
}

export interface SyncDataBatch {
  buckets: SyncBucketData[];
}

export interface SyncBucketData {
  bucket: string;
  data: OplogEntry[];
  /**
   * True if the response does not contain all the data for this bucket, and another request must be made.
   */
  has_more: boolean;
  /**
   * The `after` specified in the request.
   */
  after: OpId;
  /**
   * Use this for the next request.
   */
  next_after: OpId;
}

export interface OplogEntry {
  op_id: OpId;
  op: 'PUT' | 'REMOVE' | 'MOVE' | 'CLEAR';
  object_type?: string;
  object_id?: string;
  data?: Record<string, any>;
  checksum: number;
}

export interface BucketChecksum {
  bucket: string;
  /**
   * 32-bit unsigned hash.
   */
  checksum: number;

  /**
   * Count of operations - informational only.
   */
  count: number;
}

export function isContinueCheckpointRequest(request: SyncRequest): request is ContinueCheckpointRequest {
  return (
    Array.isArray((request as ContinueCheckpointRequest).buckets) &&
    typeof (request as ContinueCheckpointRequest).checkpoint_token == 'string'
  );
}

export function isSyncNewCheckpointRequest(request: SyncRequest): request is SyncNewCheckpointRequest {
  return typeof (request as SyncNewCheckpointRequest).request_checkpoint == 'object';
}

/**
 * For crud.json
 */
export interface CrudRequest {
  data: CrudEntry[];
}

export interface CrudEntry {
  op: 'PUT' | 'PATCH' | 'DELETE';
  type: string;
  id: string;
  data: string;
}

export interface CrudResponse {
  /**
   * A sync response with a checkpoint >= this checkpoint would contain all the changes in this request.
   *
   * Any earlier checkpoint may or may not contain these changes.
   *
   * May be empty when the request contains no ops.
   */
  checkpoint?: OpId;
}
