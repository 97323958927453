import type { Sqlite } from './SqliteEngineV1';

/**
 * A collection of internal utility functions.
 */

/**
 * Validate that arguments are the correct type.
 *
 * This is intended to fail early on bugs. If this has a significant performance overhead,
 * this function may be removed for production builds.
 */
function validateArgs(args: Sqlite.Parameter[]) {
  for (let arg of args) {
    if (arg == null || typeof arg == 'string' || typeof arg == 'number') {
      continue;
    } else if (typeof arg == 'object') {
      continue;
    } else {
      console.error('Invalid query argument: ', arg);
      throw new Error('Invalid query argument: ' + arg);
    }
  }
  return args;
}

export function sanitizeArgs(args: Sqlite.Parameter[]) {
  if (args == null) {
    return [];
  } else if (args.length == 1 && Array.isArray(args[0])) {
    return validateArgs(args[0] as any as Sqlite.Parameter[]);
  } else {
    return validateArgs(args);
  }
}

export function sanitizeQuery(query: Sqlite.Query): { sql: string; args: Sqlite.Parameter[] } {
  if (typeof query == 'string') {
    return {
      sql: query,
      args: []
    };
  } else if (Array.isArray(query)) {
    return {
      sql: query[0],
      args: sanitizeArgs(query.slice(1))
    };
  } else if (Object.prototype.toString.call(query) == '[object Generator]') {
    throw new Error('Nested iterators not allowed. Perhaps you are missing a `yield *iterator` somewhere?');
  } else {
    throw new Error('Invalid query type: ' + query);
  }
}

export function matchingArray(iterator: Iterable<any>): any[] {
  if (Array.isArray(iterator)) {
    // We know the length already - this should be faster
    return new Array(iterator.length);
  } else {
    // We don't know the length
    return [];
  }
}
