import { useEffect, useRef, useState } from 'react';
import { defaultDb, SqlFragment } from './index.js';
import { Mutex } from './sqlite/Mutex';

const BLANK_DATA = { columns: [], rows: [] };

export function useQuery(query: SqlFragment) {
  let aborted = false;

  const [data, setData] = useState(BLANK_DATA);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const dataRef = useRef<typeof BLANK_DATA>();
  dataRef.current = data;

  async function refreshData() {
    while (defaultDb == null) {
      if (aborted) {
        return;
      }
      await new Promise((resolve) => setTimeout(resolve, 10));
    }

    try {
      if (aborted) {
        return;
      }
      if (query == null || query.sql == '') {
        throw new Error('No query');
      } else if (!query.sql.startsWith('SELECT')) {
        throw new Error('Query must start with "SELECT"');
      }
      const results = await defaultDb.query(query);
      if (JSON.stringify(dataRef.current) != JSON.stringify(results) || error != null) {
        setData(results);
        setError(null);
      }
      // console.log('loading', (results == null || results == BLANK_DATA || !defaultDb.completedSync));
      setLoading(results == null || results == BLANK_DATA || !defaultDb.completedSync);
    } catch (e) {
      setError(e.message);
    }
  }

  useEffect(() => {
    const mutex = new Mutex();

    let queued = true;

    const refresh = async (reason) => {
      queued = true;
      await mutex.exclusiveLock(async () => {
        if (!queued) {
          return;
        }
        queued = false;
        await refreshData();
      });
    };

    const t = setTimeout(() => {
      refresh('timer');
    }, 100);
    const t2 = setInterval(() => {
      refresh('interval');
    }, 1000);

    const ref = defaultDb.internaldb.onChange(() => {
      refresh('onchange');
    });

    return () => {
      aborted = true;
      ref();
      clearTimeout(t);
      clearInterval(t2);
    };
  }, [query]);

  return { data, error, loading: loading && error == null };
}
