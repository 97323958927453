import { db } from './db';
import { sql } from './powersync-client';

import * as React from 'react';
import { InteractiveTable } from './QueryTable';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

async function loadFaker() {
  return import('@faker-js/faker/locale/en_US');
}

export function AssetsPage() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <AssetsTable />
        </Paper>
      </Grid>
    </Grid>
  );
}

export function AssetsTable() {
  const insert = async () => {
    const { faker } = await loadFaker();

    await db.query(sql`INSERT INTO assets(
                  id,
                  make,
                  model,
                  serial_number,
                  quantity,
                  customer_id,
                  user_id
                ) VALUES (
                  ${faker.datatype.uuid()},
                  ${faker.vehicle.manufacturer()},
                  ${faker.vehicle.model()},
                  ${faker.vehicle.vin()},
                  1,
                  (SELECT id FROM customers ORDER BY RANDOM() LIMIT 1),
                  user_id()
                )`);
  };

  const del = async (ids: any[]) => {
    console.log('delete', ids);
    await db.query(sql`DELETE FROM assets WHERE id IN (SELECT json_each.value FROM json_each(${JSON.stringify(ids)}))`);
  };

  return (
    <InteractiveTable
      title={'Assets'}
      queries={[
        'SELECT assets.*, customers.name AS customer_name, customers.email AS customer_email FROM assets LEFT JOIN customers ON assets.customer_id = customers.id ORDER BY created_at NULLS LAST',
        'SELECT * FROM assets'
      ]}
      insert={insert}
      delete={del}
    />
  );
}
